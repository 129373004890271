.MuiDrawer-paperAnchorLeft {
	width: 100%;
}

.top-header {
	position: sticky;
	top: 0;
	background: #fff;
	box-shadow: 0 3px 30px #00000029;
	line-height: 69px;
	padding: 5px 20px;
	display: flex;
	justify-content: space-between;
	align-items: "center";
	z-index: 999;

	.mobileMenu {
		display: none;
	}

	.left,
	.right {
		display: flex;
		align-items: center;
	}

	.right {
		.header-logo-wrapper {
			display: flex;
			height: fit-content;
			margin: auto;
			.header-logo {
				width: 190px;
			}
		}
	}

	.left {
		direction: rtl;
		.header-nav {
			color: #888787;
			text-decoration: none;
			padding: 0 13px;
			font-size: 14px;
		}

		.mobile-menu {
			display: none;
		}

		.header-nav:hover {
			color: #7265b8;
			text-decoration: 4px solid #c7db8a underline;
			text-underline-position: under;
		}

		.search-form {
			display: flex;
			direction: rtl;
			align-items: center;
			height: 50px;
			border: 1px solid #d6d4d4;
			width: 250px;
			border-radius: 50px;
			box-sizing: border-box;
			justify-content: space-between;
			overflow: hidden;
			box-shadow: 0 0 20px #d6d4d461;

			.search-icon {
				color: #7265b8;
				padding: 0 10px;
			}

			.header-search-input {
				width: 100%;
				height: 100%;
				border: none;
				background: none;
				outline: none;
				box-sizing: border-box;
				font-size: 13px;
			}

			.header-search-btn {
				width: 50px;
				border: none;
				background: none;
				cursor: pointer;
				outline: none;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}

@media only screen and (max-width: 1000px) {
	.top-header {
		padding: 5px;
		.left {
			.header-nav {
				display: none;
			}

			.search-form {
				display: none;
			}

			.mobile-menu {
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				background-color: #7265b8;
				color: #fff;
				border: none;
				outline: none;
				cursor: pointer;
				width: 40px;
				height: 40px;
				margin: 14.5px;
			}
		}
	}
}
