.thank-you
{
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    border-radius: 0.125rem;
    background-color: #fff;
    padding: 40px 0px;
    margin: 40px 0px;
    box-sizing: border-box;
    flex: 1;


    .message-title
    {
        font-size: 3rem;
        font-weight: 300;
        line-height: 1.2;
        margin-bottom: 0.5rem;
        margin-top: 0px;
        text-align: center;
        direction: rtl;
    }

    .order_content
    {
        text-align: center;

        .title
        {
            font-weight: bold;
            font-size: 1.25rem;
        }

        .value
        {
            margin-right: 5px;
            font-size: 1.25rem;
            font-weight: 300;
        }
    }
}