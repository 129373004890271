.page-content {
	width: 100%;
	.system-content {
		padding: 30px;
		width: 1080px;
		max-width: 100%;
		box-sizing: border-box;
		margin: 50px auto;

		.system-title {
			font-size: 22px;
			text-align: center;
			color: #404040;
		}

		.system-buttons {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			direction: rtl;
			margin-bottom: 20px;

			.system-button {
				background: linear-gradient(-232.56deg, var(--c2, --c2), var(--c1, --c1) 51%, var(--c2, --c2)) var(--x, 0) / 200%;
				display: inline-block;
				padding: 0.5rem 1.6rem;
				cursor: pointer;
				text-transform: capitalize;
				--c1: #7265b8;
				--c2: #0c0a37;
				color: #fff;
				font-size: 14px;
				margin: 0.375rem;
				min-width: 200px;
				text-align: center;
				text-decoration: none;
				border-radius: 0.125rem;
				box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
			}

			.system-button:hover {
				background: linear-gradient(-232.56deg, var(--c2, --c2), var(--c1, --c1) 51%, var(--c2, --c2)) var(--x, 0) / 200%;
				--c1: #0c0a37;
				--c2: #7265b8;
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.page-content {
		.system-content {
			.content_first {
				flex-wrap: wrap-reverse;

				.right,
				.left {
					width: 100%;

					.content-row {
						.box-content {
							width: 100%;
						}

						.content-value {
							width: 100%;
							margin: 10px 0;
						}
					}
				}
			}

			.text-tool-bar {
				flex-wrap: wrap;
				.left,
				.right {
					width: 100%;
					flex-wrap: wrap;
					direction: rtl;
				}
			}
		}
	}
}
