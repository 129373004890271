.modal-bg {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	overflow: hidden;

	.modal {
		position: absolute;
		width: 1000px;
		max-width: 95%;
		margin: auto;
		bottom: 30px;
		top: 30px;
		box-sizing: border-box;
		right: 0;
		left: 0;
		overflow: hidden;
		background-color: #fff;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		animation: popUp 0.4s ease-in-out;

		@keyframes popUp {
			from {
				width: 0;
				max-height: 0;
				opacity: 0;
			}
			to {
				width: 1000px;
				max-height: 100%;
				opacity: 1;
			}
		}

		.modal-header {
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid #ddd;
			height: 50px;
			align-items: center;
			padding: 0px 10px;
			position: sticky;
			background-color: #fff;
			top: 0;

			.modal-title {
				font-weight: 600;
				letter-spacing: 1.5px;
				font-size: 18px;
				text-transform: capitalize;
			}

			.close-button {
				color: #7a869a;
				cursor: pointer;
				transition: 0.2s ease-in-out;
			}

			.close-button:hover {
				color: black;
				transform: rotate(90deg);
			}
		}

		.modal-content {
			padding: 0px 10px;
			min-height: 200px;
			overflow: auto;
			height: 100%;
			margin-bottom: 10px;
		}

		.modal-content::-webkit-scrollbar {
			width: 6px;
			height: 6px;
		}

		.modal-content::-webkit-scrollbar-track {
			border-radius: 10px;
		}

		.modal-content::-webkit-scrollbar-thumb {
			background-color: var(--main-color);
			border-radius: 10px;
		}
	}
}
