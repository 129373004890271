.page-content {
	.content {
		display: flex;
		justify-content: space-between;
		padding: 10px;
		gap: 20px;
		width: 100%;
		overflow: hidden;
		box-sizing: border-box;

		.left {
			width: 70%;
			direction: rtl;
			margin: 0 30px;

			.slide-image {
				display: "flex";
				align-items: center;
				justify-content: center;
				background-size: cover;
				height: 350px;
				border-radius: 25px;
			}

			.content-section {
				margin-bottom: 60px;
				.section-title {
					color: #7265b8;
					padding: 0 0 15px 5px;
					margin-bottom: 0;
					font-size: 22px;
				}

				.scroll-container {
					display: flex;
					gap: 10px;

					.scroll-card {
						background-image: url(../../pictures/clr-bg.png);
						background-size: cover;
						background-repeat: no-repeat;
						border: 1px solid #d9e5f0;
						padding: 15px 10px;
						border-radius: 25px;
						text-align: center;
						margin: 0 2px;

						.card-content {
							width: 200px;
							height: 180px;
							padding: 5px;

							.card-image {
								width: 100%;
								height: 150px;
							}

							.card-title {
								color: #337ab7;
								font-size: 16px;
								padding: 0px 0 10px 0;
								font-weight: bold;
								text-decoration: none;
								margin: 0;
							}

							.card-desc {
								font-size: 12px;
								color: #747474;
								padding: 0px 0 25px 0;
								line-height: 1.5;
								margin: 0;
							}
						}

						.card-nav {
							background-color: #7265b8;
							border: 2px solid #7265b8;
							color: #fff;
							padding: 0px 20px;
							text-decoration: none;
							border-radius: 25px;
							transition: 0.3s;
						}
					}

					.scroll-card:hover {
						background-color: #e4f4f7;
					}
				}

				.container {
					display: grid;
					direction: rtl;
					grid-template-areas:
						"main card1 card2 card3"
						"main card4 card5 card6";
					grid-auto-rows: 190px;
					grid-gap: 10px;

					.service-main {
						grid-area: main;
						background-image: url(../../pictures/serv.webp);
						background-size: cover;
						background-position: center;
						object-fit: fill;
						padding: 12% 17% 0 17%;
						border-radius: 25px;
						font-size: 35px;
					}

					.service-card {
						grid-area: card;
						background-color: #ffff;
						border: 1px solid #d9e5f0;
						padding: 25px;
						border-radius: 25px;
						margin: 4px;
						background-size: cover;
						background-repeat: no-repeat;
						transition: 0.3s;
						position: relative;

						.service-card-content {
							position: absolute;
							color: #337ab7;
							bottom: 15px;

							.card-label {
								margin: 0;
								font-weight: 600;
								font-size: 17px;
							}

							.card-value {
								margin: 0;
								font-size: 13px;
							}
						}
					}

					.service-card:hover {
						background-color: #e4f4f7;
					}
				}

				.container-actions {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: 20px;

					.action-btn {
						border: 1px solid #d9e5f0;
						background-color: transparent;
						border-radius: 25px;
						color: #000;
						padding: 5px 15px;
						margin: 15px 0 0 0;
						transition: 0.3s;
						cursor: pointer;
					}

					.action-btn:hover {
						border: 1px solid #7265b8;
					}
				}

				.home-bottom-image {
					width: 100%;
					user-drag: none;
					-webkit-user-drag: none;
					user-select: none;
					-moz-user-select: none;
					-webkit-user-select: none;
					-ms-user-select: none;
				}

				.home-bottom-image-mobile {
					display: none;
				}
			}
		}

		.right {
			width: 30%;
		}
	}
}

@media only screen and (max-width: 1000px) {
	.page-content {
		.content {
			.left {
				width: calc(100% - 10px);
				margin: 0 5px;

				.slide-image {
					height: 200px;
				}
			}

			.right {
				display: none;
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.page-content {
		.content {
			.left {
				.content-section {
					.home-bottom-image {
						display: none;
					}

					.home-bottom-image-mobile {
						display: block;
						width: 100%;
					}

					.container {
						grid-template-areas:
							"main"
							"card1"
							"card2"
							"card3"
							"card4"
							"card5"
							"card6";
						grid-auto-rows: 190px;
					}
				}
			}
		}
	}
}
