.bottom-nav {
	.website-pages-bg {
		background-image: url(../../pictures/footer-bg.webp);
		background-size: cover;
		background-repeat: no-repeat;

		.website-pages {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			direction: rtl;
			margin: 0 auto;
			align-items: center;

			.website-pages-column {
				width: 100%;
				padding: 20px;

				.website-pages-title,
				.website-pages-nav {
					display: block;
					text-align: center;
					line-height: 1.2;
				}

				.website-pages-title {
					color: #000;
					font-weight: 700;
					font-size: 1rem;
					margin-bottom: 0.5rem;
				}

				.website-pages-nav {
					color: #000;
					font-size: 13px;
					font-weight: 300;
					cursor: pointer;
					text-decoration: none;
					margin: auto;
					margin-bottom: 1rem;
					width: -webkit-fit-content;
					width: -moz-fit-content;
					width: fit-content;
					transition: 0.2s ease-in-out;

					#twitter_icon {
						background-color: #55acee;
						color: #fff;
						font-size: large;
						padding: 10px;
						border-radius: 50%;
						-webkit-transition: 0.2s ease-in-out;
						-o-transition: 0.2s ease-in-out;
						transition: 0.2s ease-in-out;
					}

					#twitter_icon:hover {
						-webkit-box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
						box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
					}
				}

				.website-pages-nav:hover {
					color: #7265b8;
				}

				.column-row {
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					-webkit-box-pack: center;
					-ms-flex-pack: center;
					justify-content: center;
					margin-bottom: 10px;

					.contact-us-image {
						width: 20px;
						margin-left: 5px;
					}

					.free-text {
						font-size: 1rem;
						font-weight: 300;
						line-height: 1.2;
						color: #000;
						text-decoration: none;
					}

					.contact-us-num {
						font-size: 1rem;
						font-weight: 300;
						line-height: 1.2;
						color: #000;
						text-decoration: none;
					}

					.contact-us-num:hover {
						text-decoration: underline;
						cursor: pointer;
					}

					.reach-us-icon {
						font-size: 28px;
						cursor: pointer;
						margin: 0 5px;
						border: none;
						color: white;
						color: #7265b8;
						outline: none;
					}
				}
			}

			.website-pages-column:not(:last-child) {
				border-left: 1px solid #d9e5f0;
			}
		}
	}

	.website-rights {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		padding: 20px 0px;
		direction: rtl;
		background-color: #7265b8;

		.rights-value {
			padding: 0px 30px;
			font-size: 12px;
			color: #fff;
			font-weight: 300;

			.rights-href {
				margin: 0px 10px;
			}

			.rights-link,
			.rights-href {
				color: inherit;
				text-decoration: none;
			}

			.rights-link:hover,
			.rights-href:hover {
				text-decoration: underline;
			}
		}
	}
}

@media only screen and (max-width: 1020px) {
	.bottom-nav {
		.website-pages-bg {
			.website-pages {
				-ms-flex-wrap: wrap;
				flex-wrap: wrap;

				.website-pages-column {
					width: 80%;
					margin: 20px auto;
					border-bottom: 1px solid rgba(0, 0, 0, 0.1);
					border-left: none !important;
				}

				.website-pages-column:first-child {
					display: flex;
					gap: 10px;
					justify-content: center;
					img {
						width: 45%;
					}
				}

				.website-pages-column.last {
					border: none;
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.bottom-nav {
		.website-rights {
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;

			.rights-value {
				width: 100%;
				text-align: center;
				margin: 5px 0px;
			}
		}
	}
}
