.menu-bar {
	width: 100%;
	border-radius: 25px;
	border: 1px solid #d9e5f0;
	height: fit-content;

	.menu-header {
		height: 50px;
		text-indent: 25px;
		border: 1px solid #d6d4d4;
		background-image: url(../../pictures/search-bg.webp);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: right;
		width: 100%;
	}

	.mobile-menu-header {
		display: none;
	}

	.search-form {
		display: none;
	}

	.menu-header.rewards {
		background-image: url(../../pictures/Awards_ar.png);
		height: 70px;
		cursor: pointer;
	}

	.menu-header.dalel {
		background-image: url(../../pictures/govtservices_ar.png);
		height: 70px;
		cursor: pointer;
	}

	.menu-btn-wrapper {
		display: block;
		direction: rtl;
		position: relative;
		.menu-btn {
			display: flex;
			justify-content: space-between;
			padding: 15px;
			width: 100%;
			background-color: #fff;
			border: none;
			outline: none;
			cursor: pointer;
			align-items: center;

			* {
				cursor: pointer;
			}

			.menu-label {
				font-size: 13px;
			}

			.menu-icon {
				display: flex;
				align-items: center;
				font-size: 20px;
				transform: rotate(-90deg);
				transition: 0.2s ease-in-out;
			}
		}

		.menu-dropdown {
			max-height: 0;
			overflow: hidden;
			margin-top: -10px;
			transition: 0.2s ease-in-out;
			.menu-nav-link {
				display: block;
				width: fit-content;
				margin: 10px 30px;
				text-decoration: none;
				color: #000;
				transition: 0.2s ease-in-out;
				font-size: 12px;
			}

			.menu-nav-link:hover {
				color: #7265b8;
			}
		}
	}

	.menu-btn-wrapper.open {
		.menu-btn {
			.menu-label {
				color: #7265b8;
			}

			.menu-icon {
				display: flex;
				align-items: center;
				font-size: 20px;
				transform: rotate(90deg);
				color: #7265b8;
			}
		}

		.menu-dropdown {
			max-height: 35vh;
		}
	}
}

@media only screen and (max-width: 1000px) {
	.menu-bar {
		border: none;
		.menu-header {
			display: none;
		}

		.mobile-menu-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 5px 10px;

			.menu-header-logo {
				width: 190px;
			}

			.mobile-close-menu {
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				background-color: #7265b8;
				color: #fff;
				border: none;
				outline: none;
				cursor: pointer;
				width: 40px;
				height: 40px;
				margin: 14.5px;
			}
		}

		.search-form {
			display: flex;
			direction: rtl;
			align-items: center;
			height: 50px;
			border: 1px solid #d6d4d4;
			width: calc(100% - 20px);
			margin: 10px;
			border-radius: 50px;
			box-sizing: border-box;
			justify-content: space-between;
			overflow: hidden;
			box-shadow: 0 0 20px #d6d4d461;

			.search-icon {
				color: #7265b8;
				padding: 0 10px;
			}

			.header-search-input {
				width: 100%;
				height: 100%;
				border: none;
				background: none;
				outline: none;
				box-sizing: border-box;
				font-size: 13px;
			}

			.header-search-btn {
				width: 50px;
				border: none;
				background: none;
				cursor: pointer;
				outline: none;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}
